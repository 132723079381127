/* eslint-disable react/prop-types */
import {
    Box,
    styled
} from '@mui/material';
import {
    ArrowUpIcon,
    ArrowDownIcon,
    Move,
    Trash2,
    Edit
} from "lucide-react";

const baseButtonStyle = {
    color: "#404040",
    cursor: "pointer",
    "&:hover": {
        color: "#000"
    }
}

const getActiveStyling = ({ isActive }) => ({
    ...baseButtonStyle,
    color: isActive ? baseButtonStyle.color : "#909090",
    cursor: isActive ? "pointer" : "auto",
    "&:hover": {
        color: isActive ? baseButtonStyle.color : "#909090",
    }
})

const StyledArrowUp = styled(ArrowUpIcon)(getActiveStyling);

const StyledArrowDown = styled(ArrowDownIcon)(getActiveStyling);

const StyledMove = styled(Move)(({ isActive }) => ({
    ...getActiveStyling({ isActive }),
    marginTop: "2px",
    marginRight: "2px"
}));

// const StyledMove = styled(Move)({
//     ...baseButtonStyle,
//     marginTop: "2px",
//     marginRight: "2px"
// });

const StyledEdit = styled(Edit)({
    ...baseButtonStyle,
    marginTop: "2px",
    marginRight: "2px"
});

const StyledDelete = styled(Trash2)({
    ...baseButtonStyle,
    marginTop: "2px",
    marginRight: "2px"
});

const EditSectionControls = ({ isOnlyElement, isTopElement, isBottomElement, visible, attributes, listeners, removeSection, edit, moveUp, moveDown }) => {
    return (
        <Box sx={{
            display: visible ? "flex" : "none",
            position: "relative",
            overflow: "visible",
            width: "0px",
            height: "0px",
            direction: "rtl",
        }}>
            <Box sx={{
                display: "flex",
                gap: 1,
                backgroundColor: "white",
                height: "20px",
                paddingLeft: "20px"
            }}>
                <StyledDelete 
                    size={20}
                    sx={{
                        marginLeft: "2px"
                    }}
                    onClick={() => {
                        confirm("Are you sure you want to delete this section?") && removeSection();
                    }} />
                <StyledEdit 
                    size={20} 
                    onClick={edit}
                    sx={{
                        marginLeft: "2px"
                    }}
                    />
                <StyledArrowDown 
                       onClick={moveDown}
                       isActive={!isBottomElement && !isOnlyElement}
                    />
                <StyledArrowUp 
                    onClick={moveUp}
                    isActive={!isTopElement && !isOnlyElement}
                    />
                <StyledMove
                    size={20}
                    {...attributes}
                    {...listeners}
                    style={{ outline: "none" }}
                    isActive={!isOnlyElement}
                />
            </Box>
        </Box>
    )
}

export default EditSectionControls;