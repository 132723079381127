export const convertImageToBase64 = (url, callback, onError) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      callback(dataURL);
    };

    img.onerror = onError;

    img.crossOrigin = 'Anonymous';
    img.src = url;
};

export async function getCroppedImg(imageSrc, pixelCrop) {
  // Create an image element
  const image = new Image();

  // Set CORS settings if the image is loaded from a different origin
  image.crossOrigin = 'anonymous';

  // Set the source of the image
  image.src = imageSrc;

  // Wait for the image to be loaded
  await new Promise((resolve, reject) => {
    image.onload = resolve;
    image.onerror = reject;
  });

  // Create a canvas element
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set canvas size to the cropped area
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // Draw the image on the canvas with the crop area
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // Return a Blob URL from the canvas
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }
      blob.name = 'cropped.jpg';
      resolve(blob);
    }, 'image/png');
  });
}