/* eslint-disable react/prop-types */
import { 
    Box,
    Container,
    Paper,
    Typography,
    styled,
    Grid,
  } from '@mui/material';
import { componentKeyMap } from "./constants";
import EditSectionControls from "./Sections/EditSectionControls";
import SectionWrapper from "./Sections/SectionWrapper";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  rectSortingStrategy
} from "@dnd-kit/sortable";
import { useRef, useEffect } from "react";


const HeaderImage = styled('img')(({ theme }) => ({
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius,
}));
  
const Logo = styled('img')(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
  objectFit: 'contain',
}));

const CenterWrapper = styled(Box)({
  width: "100%",
  height: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center'
});

const MainContent = styled(Box)({
  width: "210mm",
  height: '100%',
  position: 'relative',
});

const AddSectionButton = styled(Box)({
  marginTop: "18px",
  width: "100%",
  height: "300px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "3px dashed rgb(164, 164, 164)",
  borderRadius: "15px",
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: "bold",
  color: "rgb(164, 164, 164)",
  "&:hover": {
    backgroundColor: "#f0f0f0",
    border: "3px dashed #000",
    color: "#000",
  }
});

function ReportTemplateEditorPreview({ settings, setSettings, sections, setSections, isModifyingTemplate, setAddSectionModalOpen, editSection, title, period, portfolioSubName, currency }) {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 5 } // Prevents immediate drag on click
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const handleDragEnd = (event) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
          setSections((items) => {
              const oldIndex = items.findIndex(
                  (item) => item.id === active.id
              );
              const newIndex = items.findIndex(
                  (item) => item.id === over?.id
              );

              return arrayMove(items, oldIndex, newIndex);
          });
      }
    };
    
    const getLogoPosition = () => {
        const baseStyles = {
          position: 'absolute',
          zIndex: 1,
        };
    
        const positions = {
          'top-left': { top: 20, left: 20 },
          'top-center': { top: 20, left: '50%', transform: 'translateX(-50%)' },
          'top-right': { top: 20, right: 20 },
          'middle-left': { top: '50%', left: 20, transform: 'translateY(-50%)' },
          'middle-center': { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
          'middle-right': { top: '50%', right: 20, transform: 'translateY(-50%)' },
          'bottom-left': { bottom: 20, left: 20 },
          'bottom-center': { bottom: 20, left: '50%', transform: 'translateX(-50%)' },
          'bottom-right': { bottom: 20, right: 20 },
        };
    
        return { ...baseStyles, ...positions[settings.headerPosition] };
      };

    return (
      <CenterWrapper>
        <MainContent>
          <Container id="report" maxWidth={false} sx={{ p: 4 }}>
            <Paper sx={{ p: 4, minHeight: 'calc(100vh - 64px)' }}>
              <Box sx={{ position: 'relative', mb: 4 }}>
                {settings.showHeader && (
                  <HeaderImage
                    src={settings.headerImage}
                    alt="Financial Report Header"
                  />
                )}
                {settings.showLogo && (
                  <Box sx={getLogoPosition()}>
                    <Logo
                      size={settings.logoSize * 2}
                      src={settings.logoImage}
                      alt="Company Logo"
                    />
                  </Box>
                )}
              </Box>
              
              <Typography variant="h4" gutterBottom sx={{ color: settings.primaryColor }}>
                {title || "[REPORT TITLE]"}
              </Typography>
              
              {settings.showDatePeriod && (
                <Typography variant="subtitle1" gutterBottom sx={{ color: settings.secondaryColor }}>
                  Period: {period || "[FROM DATE] - [TO DATE]"}
                </Typography>
              )}

              {settings.showPortfolioSubName && (
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Portfolio: {portfolioSubName || "[PORTFOLIO NAME]"}
                </Typography>
              )}

              {settings.showCurrencyCode && (
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Currency: {currency || "[CURRENCY CODE]"}
                </Typography>
              )}

              {/* Widgets Grid */}
              <Grid container spacing={3} sx={{ mt: 2 }}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                  <SortableContext
                      items={sections}
                      strategy={
                          rectSortingStrategy
                      }
                  >
                    {sections.map(({ id, componentKey, width, ...rest }, index) => {
                      const Component = componentKeyMap[componentKey];

                      if (!Component) {
                          console.warn(`Component not found for key: ${componentKey}`);
                          return <>
                              Failed to load component: {componentKey}
                          </>;
                      }
                      
                      return <SectionWrapper id={id} key={id} width={width}>
                        {(sectionWrapperProp) => (
                              isModifyingTemplate ? 
                              <Component
                                options={{ ...rest, width }}
                                controls={
                                  <EditSectionControls
                                    isOnlyElement={sections.length === 1}
                                    isTopElement={index === 0}
                                    isBottomElement={index === sections.length - 1}
                                    visible={sectionWrapperProp.isHovered}
                                    attributes={sectionWrapperProp.attributes}
                                    listeners={sectionWrapperProp.listeners}
                                    removeSection={() => {
                                      setSections((sections) => sections.filter((section) => section.id !== id).map((section, index) => { return {...section, id: index } }));
                                      
                                    }}
                                    edit={()=> {
                                      editSection(id);
                                    }}
                                    moveUp={() => {
                                      setSections((sections) => {
                                        const currentIndex = sections.findIndex((section) => section.id === id);
                                        const newIndex = Math.max(0, currentIndex - 1);

                                        return arrayMove(sections, currentIndex, newIndex);
                                      });
                                    }}
                                    moveDown={() => {
                                      setSections((sections) => {
                                        const currentIndex = sections.findIndex((section) => section.id === id);
                                        const newIndex = Math.min(sections.length - 1, currentIndex + 1);
                                        return arrayMove(sections, currentIndex, newIndex);
                                      });
                                    }}
                                    />
                                } />
                            : <Component options={{ ...rest, width }}/>
                          )}
                      </SectionWrapper>
                    })}
                </SortableContext>
                </DndContext>
              </Grid>
              {isModifyingTemplate && (<AddSectionButton onClick={() => setAddSectionModalOpen(true)}>
                Add section
              </AddSectionButton>)}
            </Paper>
          </Container>
        </MainContent>
      </CenterWrapper>
    );
}

export default ReportTemplateEditorPreview;