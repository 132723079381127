import { 
    Typography,
    CircularProgress,
    Box,
    styled
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import stores from "stores";
import { internalapiCall } from "api/call";
import ReportTemplateEditorPreview from "../ReportEditor/CustomerReport/TemplateEditor/ReportTemplateEditorPreview";

const CenterBox = styled(Box)({
    position: "absolute",
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem"
})

const LoadingScreen = () => {
    return <CenterBox>
        <Typography variant="h4">
            Loading Report
        </Typography>
        <CircularProgress />
    </CenterBox>
}

const CustomerReport = observer(() => {
    const { orgId, slug } = useParams();
    const [loading, setLoading] = useState(true);
    const [report, setReport] = useState(null);
    const [reportNotFound, setReportNotFound] = useState(false);
    const { snackbar, customerReport } = stores;

    useEffect(()=>{
        const fetchReport = async () => {
            const report = await customerReport.fetchReport(orgId, slug);

            if (report?.settings) {
                setReport(report);
                setLoading(false);
            }
            else {
                if (report?.error) {
                    setReportNotFound(true);
                    setLoading(false);
                }
            }
        }

        if (!report) {
            fetchReport();
        }
    }, [])

    return (
        <>
            {loading ? <LoadingScreen /> : 
                reportNotFound ? 
                    <CenterBox sx={{
                        gap: "0.1rem"
                    }}>
                        <Typography variant="h4">
                            Could not find report
                        </Typography>
                        <Typography variant="h7">
                            Please ensure that the link is correct
                        </Typography>
                    </CenterBox> :
                    <ReportTemplateEditorPreview 
                        settings={report.settings} 
                        sections={report.sections} 
                        isModifyingTemplate={false}
                        title={report.title}
                        period={customerReport.getFormattedPeriod(report.period.start, report.period.end)}
                        portfolioSubName={report?.portfolioSubName || "[PORTFOLIO NAME]"}
                        currency={report?.currencyCode || "[CURRENCY CODE]"}
                    />
            }
        </>
    )
});

export default CustomerReport;