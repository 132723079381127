import { APIS } from 'api/constants';
import { action, makeObservable, observable, runInAction } from 'mobx';
import supabase from "utils/supabase";
import snackbar from "./snackbarStore";
import {mobXStoreHelper} from "utils/mobXStoreHelper"
// TODO: This could go somewhere else
import { IMAGE_TYPES, ALLOWED_IMAGE_EXT } from "stores/PDFEditorSettings/constants";
import { convertImageToBase64, getCroppedImg } from "utils/imageManipulators";
import snackbarStore from './snackbarStore';

class OrganisationSettingsStore {
    fetched = false;
    advisors = [];
    organisationID = null;

    constructor() {
        makeObservable(this, {
            advisors: observable,
            getAdvisors: action.bound,
            addAdvisor: action.bound,
            deleteAdvisor: action.bound,
            changeAdvisorPassword: action.bound,
            initializeOrganisationSettings: action.bound,
            organisationID: observable,
        });
    }

    async getAdvisors() {
        mobXStoreHelper(APIS.GET_LIST_OF_ADVISORS, {}, (response) => {
            let advisors = [];

            response.meta.data.AdvisorList.forEach((advisor, index)=>{
                advisors.push({
                    id: advisor["AdvisorID"],
                    email: advisor["E_Mail"],
                    firstname: advisor["First_Name"],
                    lastname: advisor["Last_Name"],
                    omkpermission: advisor["OmkPermission"]
                })
            });
            
            this.advisors = advisors;
        })
    }

    async addAdvisor (advisor, callback) {
        const newAdvisor = {
            E_Mail: advisor.email,
            Password: advisor.password,
            First_Name: advisor.firstname,
            Last_Name: advisor.lastname
        };

        mobXStoreHelper(APIS.DEFINE_ADVISOR, newAdvisor, () => {
            snackbar.handleOpen("Advisor created.", "success")
            callback();
            this.getAdvisors();
        });
    }

    async deleteAdvisor (advisorId, callback) {
        const advisorDetails = {
            AdvisorID: advisorId
        };

        mobXStoreHelper(APIS.DELETE_ADVISOR, advisorDetails, () => {
            snackbar.handleOpen("Advisor deleted.", "success")
            callback();
            this.getAdvisors();
        });
    }

    async changeAdvisorPassword (email, password, callback) {
        const details = {
            E_Mail: email,
            Password: password
        };

        mobXStoreHelper(APIS.CHANGE_ADVISOR_PASSWORD, details, () => {
            snackbar.handleOpen("Advisor password updated.", "success")
            callback();
        });
    }

    async initializeOrganisationSettings(userId) {
        const employeeDataRes = await supabase
            .from('employees')
            .select('id, organisations ( id, name ), organisation_roles (id, name)')
            .eq('user_id', userId)
            .single();


            if(employeeDataRes.error) {
                snackbar.handleOpen(employeeDataRes.error.message, "error")
                return;
            }


        runInAction(() => {
            this.organisationID = employeeDataRes.data.organisations.id
        })

        // await this.getAdvisors();
        runInAction(() => this.fetched = true);
    }    
}

const organisationSettingsStore = (window.organisationSettingsStore = new OrganisationSettingsStore());

export default organisationSettingsStore;
