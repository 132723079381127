export function numberWithCommas(x, fix = 2) {
  if(!x) {
    return x
  }
  
  // Convert to string with fixed decimals without rounding
  let stringifiedNum = x.toString();
  let [integerPart, decimalPart] = stringifiedNum.split('.');

  // Ensure the decimal part has the required number of digits
  if (decimalPart) {
    decimalPart = decimalPart.slice(0, fix);
  } else {
    decimalPart = '0';
  }

  // Format the integer part with thousand separators
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Combine integer and decimal parts with a comma
  return `${integerPart}${fix > 0 ? "," : ""}${decimalPart}`;
    
  // const stringifiedNum = x.toFixed(fix).replaceAll(".", ",");
  // return stringifiedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function formatNumberByLocale (x, locale = 'da-DK', minFractionDigits = 0, maxFractionDigits = 2) {
  if(!x) {
    return x
  }
  
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  });

  return formatter.format(x);
}