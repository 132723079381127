/* eslint-disable react/prop-types */
import React, { useMemo, useState, useRef, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";
import { numberWithCommas, formatNumberByLocale } from 'utils/numberManipulators';
import { formattedDateToString, dateIntToDate } from "utils/dateFormatters"
import { Minus } from "lucide-react";

// Sample data
const lineData = [
    { XAxis: 20250101, Value: 4000, profit: 2400 },
    { XAxis: 20250201, Value: 3000, profit: 1398 },
    { XAxis: 20250301, Value: 2000, profit: 9800 },
    { XAxis: 20250401, Value: 2780, profit: 3908 },
    { XAxis: 20250501, Value: 1890, profit: 4800 },
    { XAxis: 20250601, Value: 2390, profit: 3800 }
];

// Utility to measure text width
function getTextWidth(text, font = "12px Lato, Arial, sans-serif") {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    return context.measureText(text).width * 0.5; // empirically tuned with 0.5 for SVG rendering
}

function getBottomMargin(sampleLabel = "01 Jan 2025", angleDeg = 30, font = "12px Lato, Arial, sans-serif") {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    ctx.font = font;
  
    const width = ctx.measureText(sampleLabel).width;
    const radians = (angleDeg * Math.PI) / 180;
    const estimatedHeight = Math.sin(radians) * width;
  
    return Math.ceil(estimatedHeight);
}

function computeRotatedWidth (label = "01 Jan 2024") {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    ctx.font = "12px Lato"; // match actual label font
  
    const rawWidth = ctx.measureText(label).width;
    const rotatedWidth = Math.cos((45 * Math.PI) / 180) * rawWidth + 12; // 12 = buffer
  
    return rotatedWidth;
}

function computeLabelInterval(data, chartWidth, labelWidth, angle = 45) {
    if (!data || chartWidth === 0) {
        return NaN;
    }

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    ctx.font = "12px Lato"; // match actual label font
  
    const maxLabels = Math.floor(chartWidth / labelWidth);
    return Math.max(0, Math.ceil(data.length / maxLabels));
}

function useChartWidth() {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const observer = new ResizeObserver(([entry]) => {
        if (entry.contentRect) {
            setWidth(entry.contentRect.width);
        }
        });

        observer.observe(ref.current);

        return () => observer.disconnect();
    }, []);

    return [ref, width];
}

const calculateMarginsByMaxYValuePerAxisFormattedByLocale = (data, lines, leftYAxisMaxValue, rightYAxisMaxValue) => {
    // const leftYAxisLines = lines.filter(line => line.yAxisPosition === "left");
    // const rightYAxisLines = lines.filter(line => line.yAxisPosition === "right");

    // const leftYAxisMaxValue = leftYAxisLines.reduce((max, line) => {
    //     const maxValue = Math.max(...data.map(d => d[line.calcCategory]));
    //     return Math.max(max, maxValue);
    // }, 0);

    // const rightYAxisMaxValue = rightYAxisLines.reduce((max, line) => {
    //     const maxValue = Math.max(...data.map(d => d[line.calcCategory]));
    //     return Math.max(max, maxValue);
    // }, 0);

    const leftYAxisMaxFormatted = formatNumberByLocale(leftYAxisMaxValue);
    const rightYAxisMaxFormatted = formatNumberByLocale(rightYAxisMaxValue);

    // these return width=4 when the value is 0
    const maxLeftWidth = Math.ceil(getTextWidth(leftYAxisMaxFormatted, "0.75rem Lato, Arial, sans-serif"));
    const maxRightWidth = Math.ceil(getTextWidth(rightYAxisMaxFormatted, "0.75rem Lato, Arial, sans-serif"));

    return {
        left: maxLeftWidth,
        right: maxRightWidth
    };
}

const calulateYAxisDomain = (data, lines, side = "left", marginInPercentage) => {
    const axisLines = lines.filter(line => line.yAxisPosition === side);

    const maxValue = axisLines.reduce((max, line) => {
        const maxValue = Math.max(...data.map(d => d[line.calcCategory]));
        return Math.max(max, maxValue);
    }, -Infinity); // -infinity, so we don't pick up 0 as max

    const minValue = axisLines.reduce((min, line) => {
        const minValue = Math.min(...data.map(d => d[line.calcCategory]));
        return Math.min(min, minValue);
    }, Infinity); // infinity, so we don't pick up 0 as min

    const marginValue = marginInPercentage/100; 

    const minMargin = 1 - marginValue;
    const maxMargin = 1 + marginValue;

    const min = minValue * minMargin;
    const max = maxValue * maxMargin;

    return [min, max];
}

const calulateYAxisDomains = (data, lines, marginInPercentage) => {
    const leftYAxisDomain = calulateYAxisDomain(data, lines, "left", marginInPercentage);
    const rightYAxisDomain = calulateYAxisDomain(data, lines, "right", marginInPercentage);

    return {
        left: leftYAxisDomain,
        right: rightYAxisDomain
    };
}

const LineChartSection = ({ options, controls }) => {
    const [containerRef, chartWidth] = useChartWidth();

    const longestXValue = useMemo(() => {
        return (options?.data || lineData).reduce((a, b) => (a.XAxis.length > b.XAxis.length ? a : b)).XAxis;
    }, [options?.data]);

    const XAxisLabelRotatedWidth = useMemo(() => {
        return computeRotatedWidth(longestXValue); // longestXValue not really needed as we assume dates and they all have same length
    }, [longestXValue]);

    const yAxisDomains = useMemo(() => {
        return calulateYAxisDomains(options?.data || lineData, options?.lines, 1);
    }, [options?.data, options?.lines]);

    const margins = useMemo(()=>{
        return calculateMarginsByMaxYValuePerAxisFormattedByLocale(
            options?.data || lineData, options?.lines, 
            yAxisDomains.left[1], 
            yAxisDomains.right[1]
        );
    }, [options?.data, options?.lines, yAxisDomains.left, yAxisDomains.right]);

    const interval = useMemo(() => {
        return computeLabelInterval(options?.data || lineData, chartWidth-margins.left-margins.right, XAxisLabelRotatedWidth)
    }, [chartWidth, margins.left, margins.right, XAxisLabelRotatedWidth, options?.data]);

    const isXAxisDense = interval > 2;
    const bottomMargin = getBottomMargin(longestXValue, 45) + 10; // 10 = buffer for legend

    return (
        <Box sx={{
            p: 3
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <Typography variant="h6" gutterBottom>
                    {options?.title}
                </Typography>
                {controls}
            </Box>
            <Box sx={{ height: 300 }} ref={containerRef} >
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart 
                        data={options?.data || lineData}
                        margin={{ 
                            left: (options?.lines).filter(line => line.yAxisPosition === "left").length === 0 ? XAxisLabelRotatedWidth : margins.left,
                            right: margins.right,
                            bottom: isXAxisDense ? bottomMargin : 0
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            tickFormatter={(x) => {
                                const xAxisIsDate = (typeof x === "number" && x.toString().length === 8);
                                const newX = xAxisIsDate ? 
                                    formattedDateToString(dateIntToDate(x), "DD MMM. YYYY")
                                    : x;

                                return newX;
                            }}
                            dataKey="XAxis" 
                            angle={isXAxisDense ? -45 : 0}
                            textAnchor={isXAxisDense ? "end" : "middle"}
                            interval={interval} // adjust based on data density
                        />
                         {
                            options?.lines?.filter((line) => line.yAxisPosition === "left").length > 0 && (
                                <YAxis 
                                    yAxisId="left"
                                    orientation={"left"}
                                    label={{
                                        value: options?.yAxisLeftLabel || options?.calcNames?.[0] || "",
                                        angle: -90,
                                        position: "outsideLeft",
                                        dx: -15-margins.left
                                    }}
                                    tickFormatter={(y) => {
                                        return formatNumberByLocale(y);
                                    }}
                                    domain={yAxisDomains.left}
                                    />
                            )
                        }
                        {
                            options?.lines?.filter((line) => line.yAxisPosition === "right").length > 0 && (
                                <YAxis 
                                    yAxisId="right"
                                    orientation={"right"}
                                    label={{
                                        value: options?.yAxisRightLabel || options?.calcNames?.[1] || "",
                                        angle: 90,
                                        position: "outsideRight",
                                        dx: 15+margins.right
                                    }}
                                    tickFormatter={(y) => {
                                        return formatNumberByLocale(y);
                                    }}
                                    domain={yAxisDomains.right}
                                     />
                            )
                        }
                        <Tooltip 
                            formatter={(value) => {                                
                                return formatNumberByLocale(value);
                            }}
                            labelFormatter={(value) => {
                                return formattedDateToString(dateIntToDate(value), "DD MMM. YYYY");
                            }}
                        />
                        {/* <Legend 
                            //   verticalAlign="bottom"
                            //   align="center"
                              height={10}
                              wrapperStyle={{ marginTop: 10 }} // Adds spacing between axis and legend
                              /> */}
                        {/* <Legend 
                            content={<Box sx={{
                                mt: `${bottomMargin}px`
                            }}>
                                {options?.lines?.map((line, index) => (
                                    <Box 
                                        key={index} 
                                        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        ● AUM
                                    </Box>
                                ))}
                            </Box>}
                            /> */}
                        {
                            options?.lines?.length > 0 && (
                                <>
                                    <Line
                                        type="monotone"
                                        dataKey={options?.data ? options?.lines[0]?.calcCategory : "Value"}
                                        stroke={options?.lines?.[0]?.color || "#8884d8"}
                                        name={options?.lines?.[0]?.label}
                                        yAxisId={options?.lines?.[0]?.yAxisPosition || "left"}
                                        dot={false}
                                        strokeWidth={2}
                                    />
                                    {options?.lines?.length > 1 && (
                                        <>
                                            <Line
                                                type="monotone"
                                                dataKey={options?.data ? options?.lines[1]?.calcCategory : "profit"}
                                                stroke={options?.lines?.[1]?.color || "#82ca9d"}
                                                name={options?.lines?.[1]?.label}
                                                yAxisId={options?.lines?.[1]?.yAxisPosition || "left"}
                                                dot={false}
                                                strokeWidth={2}
                                            />
                                        </>
                                    )}
                                </>
                            )
                        }
                        
                    </LineChart>
                </ResponsiveContainer>
                <Box sx={{
                        // mt: `${bottomMargin}px`
                        position: "relative",
                        top: "-10px",
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        gap: "10px"
                    }}>
                        {options?.lines?.map((line, index) => (
                            <Box 
                                key={index} 
                                sx={{ 
                                    display: "flex",
                                    alignItems: "center",
                                    color: line.color || "#8884d8"
                                }}>
                                <Minus /> 
                                {line.label}
                            </Box>
                        ))}
                    </Box>
            </Box>
        </Box>
    );
};

export default LineChartSection;
