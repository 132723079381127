/* eslint-disable react/prop-types */
import {
    Box,
    Button,
    Container,
    Paper,
    Slider,
    Stack,
    Switch,
    Typography,
    styled,
    Grid
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";

const preview_columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "project", headerName: "Project", width: 200 },
    { field: "status", headerName: "Status", width: 130 },
    { field: "progress", headerName: "Progress", width: 130, type: "number" },
    { field: "startDate", headerName: "Start Date", width: 130 },
    { field: "endDate", headerName: "End Date", width: 130 },
    { field: "budget", headerName: "Budget", width: 130, type: "number" }
];

const preview_rows = [
    {
        id: 1,
        project: "Market Expansion",
        status: "In Progress",
        progress: 65,
        startDate: "2024-01-01",
        endDate: "2024-06-30",
        budget: 50000
    },
    {
        id: 2,
        project: "Product Launch",
        status: "Planning",
        progress: 25,
        startDate: "2024-02-15",
        endDate: "2024-08-31",
        budget: 75000
    },
    {
        id: 3,
        project: "Team Training",
        status: "Completed",
        progress: 100,
        startDate: "2024-01-15",
        endDate: "2024-03-15",
        budget: 25000
    },
    {
        id: 4,
        project: "Infrastructure Upgrade",
        status: "On Hold",
        progress: 45,
        startDate: "2024-03-01",
        endDate: "2024-09-30",
        budget: 120000
    },
    {
        id: 5,
        project: "Client Portal",
        status: "In Progress",
        progress: 80,
        startDate: "2024-01-01",
        endDate: "2024-04-30",
        budget: 60000
    }
];

const contructPlaceholderRows = (columns) => {
    return Array.from({ length: 2 }, (_, index) => {
        const row = {};
        columns.forEach((column, columnIndex) => {
            row.id = index+1;
            row[column.field] = "data";
        });
        return row;
    });
}

const TableSection = ({ options, controls }) => {
    const [rows, setRows] = useState(options?.columns ? contructPlaceholderRows(options?.columns) : preview_rows);
    const [columns, setColumns] = useState(options?.columns || preview_columns);

    useEffect(()=>{
        setColumns(options?.columns || preview_columns)
        setRows(options?.columns ? contructPlaceholderRows(options?.columns) : preview_rows);
    }, [options])

    return (
        <>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 3
            }}>
                <Typography variant="h6" gutterBottom>
                    {options?.title}
                </Typography>
                {controls}
            </Box>
            <Box sx={{ width: "100%" }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{

                    }}
                    disableRowSelectionOnClick
                    hideFooter={true}
                    rowHeight={40}
                    sx={{
                        // Remove scrollbar
                        "& .MuiDataGrid-scrollbar": {
                            overflow: "hidden"
                        },
                    }}
                />
            </Box>
        </>
    );
};

export default TableSection;
