// import call from 'api/call';
// import { APIS } from 'api/constants';
import { runInAction, makeAutoObservable } from 'mobx';
// import { getTodayByNumber } from "utils/dateFormatters";
import snackbar from "../../snackbarStore";
import { getUserRefreshToken } from "utils/user";
import { internalapiCall, publicApiCall } from "api/call";
import { dateToInt, formattedDateToString } from "utils/dateFormatters"
import supabase from "utils/supabase";
import { ALLOWED_IMAGE_EXT, IMAGE_TYPES, IMAGE_FOLDERS } from "./constants";
import { convertImageToBase64 } from "utils/imageManipulators";
import call from "api/call";
import { APIS } from 'api/constants';
import { environments } from "utils/environments";


class CustomerReportStore {
    loading = false;
    templates = null;
    loadingTemplates = true;
    selectedTemplate = null;
    loadingSelectedTemplate = true;
    customerId = "";
    portfolioId = "";
    fromDate = null; // actual date object
    toDate = null; // actual date object
    earliestFromDate = null;
    latestFromDate = null;
    earliestToDate = null;
    latestToDate = null;
    fetchedTemplate = null;
    reportTitle = "Financial Report";
    reports = null;
    reportsFetched = false;

    constructor() {
        makeAutoObservable(this);

        this.latestFromDate = this.toDate;
    }

    clearSelection () {
        runInAction(()=>{
            this.selectedTemplate = null;
            this.loadingSelectedTemplate = true;
            this.loadingTemplates = true;
            this.templates = null;
            this.fetchedTemplate = null;
            this.customerId = "";
            this.portfolioId = "";
            this.reportTitle = "Financial Report";
            this.setDates(null, null);
        })
    }

    setCustomerId (id) {
        runInAction(()=>{
            this.portfolioId = "";
            this.setDates(null, null);
            this.customerId = id;
        })
    }

    setPortfolio (portfolio) {
        runInAction(()=>{
            this.portfolioId = portfolio.id;
  
            if (portfolio.MinHistDate && portfolio.MaxHistDate) {
                this.setDates(portfolio.MinHistDate, portfolio.MaxHistDate);
            }
            else {
                this.setDates(null, null);
            }
        })
    }

    setDates (from, to) {
        // console.log("from", from, "to", to);
        this.earliestFromDate = this.earliestToDate = this.fromDate  = from;
        this.latestFromDate = this.latestToDate = this.toDate = to;
    }

    setReportDate(newDate, type) {
        if (type === "To") {
            this.toDate = newDate;

            if (this.fromDate > newDate) {
                this.fromDate = newDate;
            }

            this.latestFromDate = newDate;
        } else {
            this.fromDate = newDate;
        }
    }

    setSelectedTemplate (template) {
        this.selectedTemplate = template;
    }

    async fetchTemplates() {
        const refreshToken = getUserRefreshToken();

        if (!refreshToken) {
            snackbar.handleOpen("Failed to fetch templates: missing refresh token", "error");
            return;
        }

        const resp = await internalapiCall("GET", `/report/customer-report/template?refreshToken=${refreshToken}&templateID=${"all"}`, {});

        if (resp?.data?.templates) {
            runInAction(() => {
                this.templates = resp.data.templates;
                this.loadingTemplates = false;
            });
            return resp.data.templates;
        }

        snackbar.handleOpen("Failed to load templates", "error");
    }

    async refetchTemplates () {
        runInAction(() => {
            this.loadingTemplates = true;
            this.templates = [];
        });

        await this.fetchTemplates();
    }

    getImageBasePath (type, organisationId, imgName) {
        const baseEnvFolder = process.env.NODE_ENV === environments.production ? "prod" : "dev";
        return `${baseEnvFolder}/${organisationId}/${type}/${imgName}`;
    }

    async fetchImageUrl (basePath, name) {
        const bannerImgResponse = await supabase.storage.from('Images').createSignedUrl(`${basePath}/${name}.${ALLOWED_IMAGE_EXT}`, 3600);

        if (bannerImgResponse.error) {
            return null;
        }
        
        return bannerImgResponse.data.signedUrl;
    }

    async urlToBase64 (url) {
        return new Promise((resolve, reject) => {
            convertImageToBase64(url, (base64ImageURL) => {
                resolve(base64ImageURL);
            }, () => {
                resolve(null);
            });
        })
    }

    async fetchTemplate (templateID) {
        this.fetchedTemplate = null;

        const refreshToken = getUserRefreshToken();

        if (!refreshToken) {
            snackbar.handleOpen("Failed to fetch template: missing refresh token", "error");
            return;
        }

        const resp = await internalapiCall("GET", `/report/customer-report/template?refreshToken=${refreshToken}&templateID=${templateID}`, {});

        if (!resp?.data?.template) {
            snackbar.handleOpen("Failed to fetch template: not found", "error");
            return null;
        }

        const template = resp.data.template;

        const sectionsWithUniqueId = template?.sections.map((section, index) => {
            return {
                ...section,
                id: index
            };
        });
        
        template.sections = sectionsWithUniqueId;

        const baseImgPath = this.getImageBasePath(IMAGE_FOLDERS.templates, template.organisationID, templateID);

        const headerImageUrl = await this.fetchImageUrl(baseImgPath, IMAGE_TYPES.headerBanner);
        const logoImageUrl = await this.fetchImageUrl(baseImgPath, IMAGE_TYPES.companyLogo);

        template.settings.headerImage = headerImageUrl ? await this.urlToBase64(headerImageUrl) : "";
        template.settings.logoImage = logoImageUrl ? await this.urlToBase64(logoImageUrl) : "";

        this.fetchedTemplate = template;

        return template;
    }

    reportRequestPayloadReady () {
        // console.log("requestPayloadReady", !!this.selectedTemplate, !!this.fetchedTemplate, !!this.customerId, !!this.portfolioId, !!this.fromDate, !!this.toDate);
        return !!(this.selectedTemplate && this.fetchedTemplate && this.customerId && this.portfolioId && this.fromDate && this.toDate && this.reportTitle.length > 0);
    }

    async fetchReportData (templateID) {
        // if (true) {
        //     this.fetchedTemplate = {
        //         ...this.fetchedTemplate,
        //         sections: [],
        //         currencyCode: "USD",
        //         portfolioSubName: "Hans larsen",
        //         dataLoaded: true
        //     };

        //     return;
        // }
        if (!this.reportRequestPayloadReady()) {
            return;
        }

        this.loading = true;

        const payload = {
            CustomerID: this.customerId,
            FromDate: dateToInt(this.fromDate),
            ToDate: dateToInt(this.toDate),
            PortfolioName: this.portfolioId,
            Sections: this.fetchedTemplate.sections.filter((section) => section.calcCategories).map((section)=>{
                return {
                    Category: section.calcCategories.length === 1 ? section.calcCategories[0] : section.calcCategories,
                    Type: section.componentKey.split("Section")[0],
                }
            })                
        };

        try {
            const resp = await call(APIS.CALC_REPORT, payload);

            if (!resp?.message || resp?.message?.ErrorCode !== 0) {
                if (resp?.meta?.data?.ErrorText) {
                    snackbar.handleOpen(resp?.meta?.data?.ErrorText, "error");
                }
                else {
                    snackbar.handleOpen("Failed to fetch report data", "error");
                }
                this.loading = false;
                return null;
            }
    
            if (!resp?.message?.Sections) {
                snackbar.handleOpen("No data available for selected period", "info");
                this.loading = false;
                return null;
            }
    
            if (!resp?.message?.PortfolioCurrencyCode) {
                snackbar.handleOpen("Could not fetch Currency Code", "info");
                this.loading = false;
                return null;
            }
    
            if (!resp?.message?.PortfolioSubName) {
                snackbar.handleOpen("Could not fetch Portfolio Subname", "info");
                this.loading = false;
                return null;
            }
    
            this.fetchedTemplate = {
                ...this.fetchedTemplate,
                sections: this.fetchedTemplate.sections.map((section, index) => {
                    let data = resp?.message.Sections[index].Data;
    
                    return {
                        ...section,
                        data
                    }
                }),
                currencyCode: resp?.message?.PortfolioCurrencyCode,
                portfolioSubName: resp?.message?.PortfolioSubName,
                dataLoaded: true
            };
    
            this.loading = false;
        } catch (error) { // throws error 500 when calling wrong data
            snackbar.handleOpen("Failed to fetch report data", "error");
            this.loading = false;
        }
        

        
    }

    getFormattedPeriod (from, to) {
        return formattedDateToString(from || this.fromDate, "DD MMM. YYYY") + " - " + formattedDateToString(to || this.toDate, "DD MMM. YYYY")
    }

    async createReport () {
        if (!this.fetchedTemplate) {
            snackbar.handleOpen("No template to generate report with", "error");
            return;
        }

        // if (!this.fetchedTemplate.dataLoaded) {
        //     snackbar.handleOpen("Report data not loaded", "error");
        //     return;
        // }

        if (!this.reportTitle.length) {
            snackbar.handleOpen("Report title is empty", "error");
            return;
        }

        const refreshToken = getUserRefreshToken();

        if (!refreshToken) {
            snackbar.handleOpen("Failed to fetch template: missing refresh token", "error");
            return;
        }

        this.loading = true;

        const payload ={
            title: this.reportTitle,
            customerID: this.customerId,
            portfolioName: this.portfolioId,
            portfolioSubName: this.fetchedTemplate.portfolioSubName,
            currencyCode: this.fetchedTemplate.currencyCode,
            period: {
                start: dateToInt(this.fromDate),
                end: dateToInt(this.toDate)
            },
            displayPeriod: this.getFormattedPeriod(),
            templateID: this.fetchedTemplate._id,
            sectionsWithData: this.fetchedTemplate.sections,
            refreshToken: refreshToken
        }

        const resp = await internalapiCall("POST", `/report/customer-report`, payload);

        this.loading = false;

        if (!resp?.data?.reportID) {
            if (resp?.data?.error) {
                snackbar.handleOpen(resp?.data?.error, "error");
            }
            else {
                snackbar.handleOpen("Failed to create report", "error");
            }
            
            return null;
        }

        snackbar.handleOpen("Report created", "success");

        await this.fetchReports();
        
        return resp.data.reportID;
    }

    async fetchReports () {
        this.reports = null;
        
        const refreshToken = getUserRefreshToken();

        if (!refreshToken) {
            snackbar.handleOpen("Failed to fetch reports: missing refresh token", "error");
            return;
        }

        const resp = await internalapiCall("GET", `/report/customer-report?refreshToken=${refreshToken}`);

        if (resp?.data?.reports) {
            runInAction(()=>{
                this.reports = resp.data.reports;
                this.reportsFetched = true;
            });

            return resp.data.reports;
        }

        snackbar.handleOpen("Failed to load reports", "error");
    }

    async fetchReport (orgId, slug) {
        let baseEndpoint = `/public-customer-report`;

        let resp = null;

        if (!slug) { // assume that orgId == reportId
            resp = await publicApiCall(`${baseEndpoint}?reportId=${orgId}`);
        }
        else {
            resp = await publicApiCall(`${baseEndpoint}?orgId=${orgId}&slug=${slug}`);
        }
        
        if (resp?.data?.report) {
            let report = resp.data.report;

            const baseImgPath = this.getImageBasePath(IMAGE_FOLDERS.reports, report.organisationID, report.slug);

            const headerImageUrl = await this.fetchImageUrl(baseImgPath, IMAGE_TYPES.headerBanner);
            const logoImageUrl = await this.fetchImageUrl(baseImgPath, IMAGE_TYPES.companyLogo);

            report.settings.headerImage = headerImageUrl ? await this.urlToBase64(headerImageUrl) : "";
            report.settings.logoImage = logoImageUrl ? await this.urlToBase64(logoImageUrl) : "";

            return resp.data.report
        }
        
        if (resp?.response?.data?.error) {
            return {
                error: resp?.response?.data?.error
            }
        }

        snackbar.handleOpen("Failed to fetch report", "error");

        return null;

        // const baseImgPath = `${template.organisationID}/report-templates/${templateID}`;

        // const headerImageUrl = await this.fetchImageUrl(baseImgPath, IMAGE_TYPES.headerBanner);
        // const logoImageUrl = await this.fetchImageUrl(baseImgPath, IMAGE_TYPES.companyLogo);

        // template.settings.headerImage = headerImageUrl ? await this.urlToBase64(headerImageUrl) : "";
        // template.settings.logoImage = logoImageUrl ? await this.urlToBase64(logoImageUrl) : "";
    }

    async deleteReport (reportId) {
        const refreshToken = getUserRefreshToken();
            
        if (!refreshToken) {
            snackbar.handleOpen("Failed to delete report: missing refresh token", "error");
            return;
        }

        const resp = await internalapiCall("DELETE", `/report/customer-report/${reportId}`, {
            refreshToken
        });

        if (resp?.data?.deletedReport) {
            snackbar.handleOpen("Report deleted", "success");
            this.fetchReports();
        }
        else {
            snackbar.handleOpen("Failed to delete report", "error");
        }
    }
}


const customerReportStore = (window.customerReportStore = new CustomerReportStore());

export default customerReportStore;
