/* eslint-disable react/prop-types */
import {
    Paper,
    Grid,
} from "@mui/material";
import React, { useState } from 'react';
import {
    useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SectionWrapper = ({ children, id, width }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { attributes, listeners, setNodeRef, transform, transition } =
            useSortable({ id });
  
    const style = {
        transform: CSS.Transform.toString({
            ...transform,
            scaleX: 1,
            scaleY: 1
        }),
        transition
    };

    return (
        <Grid 
            item xs={12} 
            md={width} 
            ref={setNodeRef} 
            style={style} 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            >
            <Paper>
                    {typeof children === "function" 
                        ? children({ 
                            isHovered,
                            attributes,
                            listeners,
                        }) // Pass props via function
                        : children
                    }
            </Paper>
        </Grid>
    );
}

export default SectionWrapper;