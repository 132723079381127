/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
    Box,
    Paper,
    Typography,
    Grid,
} from "@mui/material";
import {
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell
} from "recharts";

const pieData1 = [
    { Name: "USD", Value: 400 },
    { Name: "DKK", Value: 300 },
    { Name: "EUR", Value: 300 },
    { Name: "INR", Value: 200 }
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

function toFixedTruncate(num, decimals) {
    const factor = 10 ** decimals;
    return (Math.trunc(num * factor) / factor).toFixed(decimals);
}

const PieChartSection = ({ options, controls }) => {

    if (options?.data) {
        options.data = options.data.map((item, index) => {
            return {
                ...item,
                Value:  typeof item.Value === "number" ? parseFloat(toFixedTruncate(item.Value, 2)) : item.Value
            }
        })
    }

    return (
        <Box sx={{
            p: 3
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <Typography variant="h6" gutterBottom>
                    {options?.title}
                </Typography>
                {controls}
            </Box>
            <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={(options?.data || pieData1)}
                            cx="50%"
                            cy="50%"
                            innerRadius={options?.width <= 4 ? 20 : 60}
                            outerRadius={options?.width <= 4 ? 40 : 80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="Value"
                            nameKey="Name"
                            label
                        >
                            {(options?.data || pieData1).map((_, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default PieChartSection;
