import {
    PieChartSection,
    ListSection,
    LineChartSection,
    TableSection
} from "./Sections";
import { ALLOWED_IMAGE_EXT as ext, IMAGE_TYPES as imgTypes } from "stores/ReportEditor/CustomerReport/constants";

export const componentKeyMap = {
	"PieChartSection": PieChartSection,
	"LineChartSection": LineChartSection,
	"TableSection": TableSection
}

export const componentKeys = {
    PIE_CHART: "PieChartSection",
    LINE_CHART: "LineChartSection",
    TABLE: "TableSection"
}

export const ALLOWED_IMAGE_EXT = ext;
export const IMAGE_TYPES = imgTypes;

export const DEFAULT_IMAGES = {
    headerImage: "https://images.unsplash.com/photo-1579621970563-ebec7560ff3e?auto=format&fit=crop&w=1200&q=80",
    logoImage: "https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?auto=format&fit=crop&w=200&h=200&q=80",
}