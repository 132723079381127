export const ALLOWED_IMAGE_EXT = 'png'

export const IMAGE_TYPES = {
    headerBanner: 'headerBanner',
    companyLogo: 'companyLogo'
}

export const IMAGE_FOLDERS = {
    reports: 'reports',
    templates: 'report-templates'
}