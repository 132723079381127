/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Transition from 'components/Transition/Transition';
import Divider from '@mui/material/Divider';
import { createPortal } from 'react-dom';

export default function TransitionsModal(props) {
  const { open, handleClose, children, title, extendWidth, disableClose, onlyContent, notFullWidth } = props;
  const theme = useTheme()

	return createPortal(
		<MuiDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={disableClose ? null : handleClose}
      fullWidth={!notFullWidth}
      maxWidth="lg"
    >
      {!onlyContent && <>
        <DialogTitle>{title}</DialogTitle>
        <Divider />
      </>}
      <DialogContent style={{ padding: theme.spacing(2), minWidth: extendWidth ? "1200px" : "500px" }}>
        {children}
      </DialogContent>
      {!onlyContent && <>
        <Divider />
        <DialogActions style={{ padding: theme.spacing(2) }}>
          {props.renderFooter && props.renderFooter()}
        </DialogActions>
      </>}
    </MuiDialog>,
    document.body
  )
	}

TransitionsModal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  scroll: PropTypes.bool,
  title: PropTypes.string,
};
